.breadcrumbs {
  padding: 64px 0;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;
  @include font(20, 1.2, 400);
  :first-child {
    padding-left: 0;
  }
  &__link {
    position: relative;
    padding: 0 16px;
    color: $text-color;
    white-space: nowrap;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      transform: rotate(18.97deg);
      background: #000000;
      z-index: 1;
    }
  }
  span.breadcrumbs__link {
    &::after {
      display: none;
    }
  }
  &__current {
    white-space: nowrap;
    padding-left: 16px;
    color: rgba(51, 51, 51, 0.65);
  }
  @include sm() {
    padding: 16px 0 32px 0;
    @include font(16, 1.2, 400);
  }
}
