.pages-title {
  @include font(40, 1.2, 900);
  color: #000000;
  span {
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 5px;
      height: 10px;
      width: 100%;
      background: $primary-green;
      z-index: -1;
    }
  }
  @include sm() {
    @include font(30, 1.2, 900);
  }
}