@import "../utils/variables";
@import "../utils/mixins";
@import "../components/pages-title";
@import "../components/breadcrumbs";

.not-found {
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  margin-bottom: 96px;
  &__content {
    padding-top: 32px;
    p {
      margin-top: 32px;
      @include font(20, 1.6, 400);
      color: #000;
      a {
        border-bottom: 1px solid #000;
        color: #000;
      }
    }
    strong {
      color: #F26106;
      font-size: 1.2em;
      font-weight: 700; 
    }
  }
  &__link-wrapper {
    margin-top: 64px;
    display: flex;
    justify-content: center;
  }
  &__link {
    padding: 16px 24px;
    border: 1px solid #333333;
    border-radius: 100px;
    background: $primary;
    @include font(14, 24px, 700);
    color: $text-color;
    svg {
      position: relative;
      top: -5px;
      left: 0;
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      svg {
        transition: all 0.2s ease-in-out;
        top: -9px;
        left: 4px;
      }
    }
    &-secondary {
      position: relative;
      background: #222222;
      color: $primary;
      &::before {
        content: '';
        position: absolute;
        z-index: -2;
        width: 123px;
        height: 123px;
        background: url('../../images/decorations/decoration-leaf.png') center center / contain no-repeat;
        right: -46px;
        top: -46px;
      }
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 112px;
        height: 110px;
        background: url('../../images/decorations/decoration-stroke-star.png') center center / contain no-repeat;
        right: -40px;
        top: -38px;
        transform: rotate(-5.06deg);
      }
    }
  }
  @include sm() {
    margin-bottom: 48px;
    &__content {
      padding-top: 0;
      p {
        margin-top: 24px;
      }
    }
    &__link-wrapper {
      margin-top: 40px;
    }
    &__link {
      &-secondary {
        text-align: center;
        &::before, &::after {
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}